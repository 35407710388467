import { useEffect, useMemo } from "react"
import { bindActionCreators } from "redux"
import { toast, ToastContainer } from "react-toastify"
import { appWithTranslation, useTranslation } from "next-i18next"
import * as locales from "@mui/material/locale"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { DefaultSeo } from "next-seo"

import nextI18NextConfig from "@@/next-i18next.config"
import { createEmotionCache, createTssEmotionCache } from "@/utils/emotion"
import defaultTheme from "@/assets/theme"
import { commonConfig } from "@/utils/config"
import { storeAuthAction, storeBookingAppAuthAction } from "@/store"
import store from "@/store"

import { Provider } from "react-redux"
import { TssCacheProvider } from "tss-react"
import { CacheProvider, EmotionCache } from "@emotion/react"
import { CssBaseline } from "@mui/material"
import { createTheme, ThemeProvider } from "@mui/material/styles"

import LoadingScreenOverlay from "@/components/LoadingScreenOverlay"
import AlertDialog from "@/components/AlertDialog"
import SocketIOProvider from "@/contexts/SocketIO"
import ErrorBoundary from "@/components/ErrorBoundary"
import RouterLoadingLinearProgress from "@/components/RouterLoadingLinearProgress"

import { useAppDispatch, useAppSelector, useIsMounted } from "@/hooks"

import "@/assets/scss/app.scss"
import "moment/locale/zh-hk"
import "moment/locale/zh-cn"

import type { AppProps } from "next/app"
import type { ReactElement, ReactNode } from "react"
import type { NextPage } from "next"
import type { UseTranslationResponse } from "react-i18next"

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (
    page: ReactElement,
    pageProps: P,
    appProps: {
      translation: UseTranslationResponse<"common">
    },
  ) => ReactNode
}

interface MyAppProps extends AppProps {
  Component: NextPageWithLayout
  emotionCache?: EmotionCache
  tssEmotionCache?: EmotionCache
}

type SupportedLocales = keyof typeof locales

const clientSideEmotionCache = createEmotionCache()
const tssClientSideEmotionCache = createTssEmotionCache()

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const theme = createTheme(defaultTheme)

const CheckAuthUser = () => {
  const dispatch = useAppDispatch()

  const $s_userAuthCheckingErrorMessage = useAppSelector(
    state => state.auth.userAuthCheckingErrorMessage,
  )

  const $s_authAction = useMemo(
    () => bindActionCreators(storeAuthAction, dispatch),
    [dispatch],
  )
  const $s_bookingAuthAuthAction = useMemo(
    () => bindActionCreators(storeBookingAppAuthAction, dispatch),
    [dispatch],
  )

  useEffect(() => {
    if ($s_userAuthCheckingErrorMessage && isMounted()) {
      toast.error($s_userAuthCheckingErrorMessage)
    }
  }, [$s_userAuthCheckingErrorMessage])

  useEffect(() => {
    $s_authAction.checkAuthSaga()
    $s_bookingAuthAuthAction.checkBookingAppAuthSaga()
  }, [])

  const isMounted = useIsMounted()

  return null
}

const MyApp: React.FunctionComponent<MyAppProps> = props => {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    tssEmotionCache = tssClientSideEmotionCache,
    pageProps,
  } = props

  const locale =
    ((pageProps?._nextI18Next?.initialLocale || "").replace(
      "-",
      "",
    ) as SupportedLocales) || "enUS"

  const headerLocale =
    ((pageProps?._nextI18Next?.initialLocale || "").replace(
      "-",
      "_",
    ) as string) || nextI18NextConfig.i18n.defaultLocale.replace("-", "_")

  const getLayout = Component.getLayout ?? (page => page)
  const translation = useTranslation()

  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [theme, locale],
  )

  useEffect(() => {
    console.log(commonConfig.APP_VERSION)
  }, [])

  return (
    <>
      <DefaultSeo
        defaultTitle={translation.t(commonConfig.DOCUMENT_TITLE)!}
        titleTemplate={`%s | ${translation.t(commonConfig.DOCUMENT_TITLE)}`}
        openGraph={{
          type: "website",
          locale: headerLocale,
          // url: "",
          siteName: translation.t(commonConfig.DOCUMENT_TITLE)!,
        }}
      />
      <Provider store={store}>
        <CacheProvider value={emotionCache}>
          <TssCacheProvider value={tssEmotionCache}>
            <ThemeProvider theme={themeWithLocale}>
              <SocketIOProvider>
                <CssBaseline />
                <RouterLoadingLinearProgress />
                <CheckAuthUser />
                <ErrorBoundary>
                  {getLayout(<Component {...pageProps} />, pageProps, {
                    translation,
                  })}
                </ErrorBoundary>
                <LoadingScreenOverlay />
                <AlertDialog />
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar
                  closeOnClick
                />
              </SocketIOProvider>
            </ThemeProvider>
          </TssCacheProvider>
        </CacheProvider>
      </Provider>
    </>
  )
}

export default appWithTranslation(MyApp, nextI18NextConfig)
