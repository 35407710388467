import { io } from "socket.io-client"

import { jwtService } from "@/services"

import { socketIOConstants } from "@/utils/constants"

import { useEffect, useMemo, useRef } from "react"

import BookingAppSocketIOContext, {
  BookingAppSocketIOContextProps,
} from "./BookingAppSocketIO.context"

import { useAppSelector, useBookingAppSocketIO, useIsMounted } from "@/hooks"
import { useTranslation } from "next-i18next"

import type { Socket } from "socket.io-client"
import { commonConfig } from "@/utils/config"

type BookingAppSocketIOProviderProps = {
  children: React.ReactNode
}

const BookingAppSocketIOInitialization = () => {
  const { socket } = useBookingAppSocketIO()

  return null
}

const BookingAppSocketIOProvider = (props: BookingAppSocketIOProviderProps) => {
  const { children } = props

  const socketRef = useRef<Socket | null>(null)

  const { i18n } = useTranslation()

  const $s_hasBoookingAppAuth = useAppSelector(
    state => !!state.bookingAppAuth.bookingAppUser?.id,
  )

  const closeBookingAppSocketIO = () => {
    socketRef.current && socketRef.current.close()
  }

  const connectBookingAppSocketIO = () => {
    if (typeof window === "undefined" || !!socketRef.current) return
    const bookingAppToken = jwtService.getBookingAppToken()
    const bookingAppUserProfile = jwtService.getBookingAppUserProfile()
    socketRef.current = io(
      `${commonConfig.BOOKING_APP_WEBSOCKET_ENDPOINT}chat`,
      {
        timeout: 60000,
        autoConnect: true,
        forceNew: false,
        extraHeaders: {
          accesstoken: bookingAppToken,
          branchId: `${bookingAppUserProfile?.branchId}`,
          shopCode: `${bookingAppUserProfile?.shopCode}`,
          mcId: `${bookingAppUserProfile?.mcId!}`,
          firebaseToken:
            "fmJA2FjlQNmeTO9xwMQbw9:APA91bHaJk5doBgPj2R_-OE7tuMqoBKxb8QPb3nMpw5SIWRf_upqp9USAIPs7m03MvBUpEKeIVbxC_4nTMvYErA-lQyJeXOgO7bYkxz6n8G255k21afXahE",
          language: i18n.language.replace("-", "_"),
          deviceId: "db5226552aae9aea",
        },
      },
    ).connect()
  }

  if (!socketRef.current) {
    connectBookingAppSocketIO()
  }

  useEffect(() => {
    if (!isMounted()) return
    connectBookingAppSocketIO()
    return () => {
      closeBookingAppSocketIO()
    }
  }, [$s_hasBoookingAppAuth])

  const isMounted = useIsMounted()

  const value = useMemo<BookingAppSocketIOContextProps>(() => {
    return {
      socket: socketRef.current!,
    }
  }, [])

  return (
    <BookingAppSocketIOContext.Provider value={value!}>
      <BookingAppSocketIOInitialization />
      {children}
    </BookingAppSocketIOContext.Provider>
  )
}

export default BookingAppSocketIOProvider
