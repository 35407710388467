import { BookingAppAuthActionTypes, type BookingAppUser } from "./types"
import type { BookingAppAuthState, BookingAppAuthAction } from "./types"
import { jwtService } from "@/services"

export const initialState: BookingAppAuthState = {
  bookingAppUser: null,
  bookingAppUserBookingAppAuthChecking: true,
  bookingAppUserBookingAppAuthCheckingErrorMessage: "",
}

const reducer = (
  state = initialState,
  action: BookingAppAuthAction,
): BookingAppAuthState => {
  switch (action.type) {
    case BookingAppAuthActionTypes.CHECK_BOOKING_APP_AUTH_REQUESTED: {
      return {
        ...state,
        bookingAppUserBookingAppAuthChecking: true,
        bookingAppUserBookingAppAuthCheckingErrorMessage: "",
      }
    }
    case BookingAppAuthActionTypes.CHECK_BOOKING_APP_AUTH_SUCCEEDED: {
      if (!action.payload)
        return {
          ...state,
          bookingAppUser: null,
          bookingAppUserBookingAppAuthChecking: false,
          bookingAppUserBookingAppAuthCheckingErrorMessage: "",
        }
      const bookingAppUser =
        jwtService.getBookingAppUserProfile() as BookingAppAuthState["bookingAppUser"]
      return {
        ...state,
        bookingAppUser,
        bookingAppUserBookingAppAuthChecking: false,
        bookingAppUserBookingAppAuthCheckingErrorMessage: "",
      }
    }
    case BookingAppAuthActionTypes.CHECK_BOOKING_APP_AUTH_FAILED: {
      const { message, isNetworkError } = action.payload

      if (!isNetworkError) {
        jwtService.destroyBookingAppToken()
        jwtService.destroyBookingAppUserProfile()
      }

      return {
        ...state,
        bookingAppUser: isNetworkError ? state.bookingAppUser : null,
        bookingAppUserBookingAppAuthCheckingErrorMessage: message,
        bookingAppUserBookingAppAuthChecking: !!isNetworkError,
      }
    }

    case BookingAppAuthActionTypes.SIGN_IN_BOOKING_APP_SUCCEEDED: {
      const bookingAppUser = {
        ...action.payload,
        token: action.payload.token,
      } as BookingAppUser
      jwtService.saveBookingAppToken(action.payload.token)
      jwtService.saveBookingAppUserProfile(bookingAppUser)
      return {
        ...state,
        bookingAppUser,
      }
    }

    case BookingAppAuthActionTypes.SIGN_OUT_BOOKING_APP: {
      jwtService.destroyBookingAppUserProfile()
      jwtService.destroyBookingAppToken()
      return {
        ...state,
        bookingAppUser: null,
      }
    }

    default: {
      return state
    }
  }
}

export default reducer
