// Action Types
import type { SignInBookingAppPayload } from "@/utils/apis/bookingAppAuth/bookingAppAuth.api.types"

export enum BookingAppAuthActionTypes {
  SIGN_IN_BOOKING_APP_SUCCEEDED = "@@bookingAppAuth/SIGN_IN_BOOKING_APP_SUCCEEDED",

  CHECK_BOOKING_APP_AUTH_REQUESTED = "@@abookingAppAuthuth/CHECK_BOOKING_APP_AUTH_REQUESTED",
  CHECK_BOOKING_APP_AUTH_SUCCEEDED = "@@bookingAppAuth/CHECK_BOOKING_APP_AUTH_SUCCEEDED",
  CHECK_BOOKING_APP_AUTH_FAILED = "@@bookingAppAuth/CHECK_BOOKING_APP_AUTH_FAILED",

  SIGN_OUT_BOOKING_APP = "@@bookingAppAuth/SIGN_OUT_BOOKING_APP",

  // Saga
  SIGN_IN_BOOKING_APP_SAGA = "@@bookingAppAuth/SIGN_IN_BOOKING_APP_SAGA",
  CHECK_BOOKING_APP_AUTH_SAGA = "@@bookingAppAuth/CHECK_BOOKING_APP_AUTH_SAGA",
}

// State

export interface BookingAppUser {
  id: number
  mcId: string
  branchId: number
  shopCode: string
  token: string
}

export interface BookingAppAuthState {
  bookingAppUser: BookingAppUser | null
  bookingAppUserBookingAppAuthChecking: boolean
  bookingAppUserBookingAppAuthCheckingErrorMessage: string
}

// ---- Reducer Action ----

export type SignInBookingAppSucceededAction = {
  type: BookingAppAuthActionTypes.SIGN_IN_BOOKING_APP_SUCCEEDED
  payload: {
    token: string
  }
}

export type CheckBookingAppAuthRequestedAction = {
  type: BookingAppAuthActionTypes.CHECK_BOOKING_APP_AUTH_REQUESTED
}

export type CheckBookingAppAuthFailedAction = {
  type: BookingAppAuthActionTypes.CHECK_BOOKING_APP_AUTH_FAILED
  payload: {
    message: string
    isNetworkError?: boolean
  }
}

export type CheckBookingAppAuthSucceededAction = {
  type: BookingAppAuthActionTypes.CHECK_BOOKING_APP_AUTH_SUCCEEDED
  payload: BookingAppUser | null
}

export type SignOutBookingAppAction = {
  type: BookingAppAuthActionTypes.SIGN_OUT_BOOKING_APP
}

// ---- Saga Action ----

export type SignInBookingAppSagaAction = {
  type: BookingAppAuthActionTypes.SIGN_IN_BOOKING_APP_SAGA
  payload: Omit<SignInBookingAppPayload, "params"> & {
    params: {
      userName: string
      password: string
      branchId: number
      mcId: string
      shopCode: string
      firebaseToken: string
    }
  }
  meta?: {
    resolve?: (payload?: any) => void
  }
}

export type CheckBookingAppAuthSagaAction = {
  type: BookingAppAuthActionTypes.CHECK_BOOKING_APP_AUTH_SAGA
}

export type BookingAppAuthAction =
  | SignInBookingAppSucceededAction
  | CheckBookingAppAuthRequestedAction
  | CheckBookingAppAuthFailedAction
  | CheckBookingAppAuthSucceededAction
  | SignOutBookingAppAction
  | SignInBookingAppSagaAction
  | CheckBookingAppAuthSagaAction
