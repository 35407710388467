import { BookingAppAuthActionTypes } from "./types"
import type {
  SignInBookingAppSucceededAction,
  CheckBookingAppAuthRequestedAction,
  CheckBookingAppAuthFailedAction,
  CheckBookingAppAuthSucceededAction,
  SignOutBookingAppAction,
  // Saga
  SignInBookingAppSagaAction,
  CheckBookingAppAuthSagaAction,
} from "./types"

// ---- REDUCER ACTION ----

export const signInBookingAppSucceeded = (
  payload: SignInBookingAppSucceededAction["payload"],
): SignInBookingAppSucceededAction => ({
  type: BookingAppAuthActionTypes.SIGN_IN_BOOKING_APP_SUCCEEDED,
  payload,
})

export const checkBookingAppAuthRequested =
  (): CheckBookingAppAuthRequestedAction => ({
    type: BookingAppAuthActionTypes.CHECK_BOOKING_APP_AUTH_REQUESTED,
  })

export const checkBookingAppAuthFailed = (
  payload: CheckBookingAppAuthFailedAction["payload"],
): CheckBookingAppAuthFailedAction => ({
  type: BookingAppAuthActionTypes.CHECK_BOOKING_APP_AUTH_FAILED,
  payload,
})

export const checkBookingAppAuthSucceeded = (
  payload: CheckBookingAppAuthSucceededAction["payload"],
): CheckBookingAppAuthSucceededAction => ({
  type: BookingAppAuthActionTypes.CHECK_BOOKING_APP_AUTH_SUCCEEDED,
  payload,
})

export const signOutBookingApp = (): SignOutBookingAppAction => ({
  type: BookingAppAuthActionTypes.SIGN_OUT_BOOKING_APP,
})

// ---- SAGA ACTION ----

export const signInSaga = (
  payload: SignInBookingAppSagaAction["payload"],
  meta?: SignInBookingAppSagaAction["meta"],
): SignInBookingAppSagaAction => ({
  type: BookingAppAuthActionTypes.SIGN_IN_BOOKING_APP_SAGA,
  payload,
  meta,
})

export const checkBookingAppAuthSaga = (): CheckBookingAppAuthSagaAction => ({
  type: BookingAppAuthActionTypes.CHECK_BOOKING_APP_AUTH_SAGA,
})
