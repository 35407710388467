import { bookingAppAxios, AxiosResponseData } from "@/utils/axios"

import type {
  SignInBookingAppPayload,
  CheckBookingAppAuthPayload,
} from "./bookingAppAuth.api.types"

const bookingAppAuthApi = {
  signInBookingApp: (payload: SignInBookingAppPayload) => {
    return bookingAppAxios.post<AxiosResponseData>("auth/login", payload.params)
  },
  checkBookingAppAuth: (payload: CheckBookingAppAuthPayload) => {
    return bookingAppAxios.get<AxiosResponseData>("auth/validate-token", {
      params: payload.params,
    })
  },
}

export default bookingAppAuthApi
