import { createStore, applyMiddleware } from "redux"
import createSagaMiddleware from "redux-saga"
import { composeWithDevTools } from "redux-devtools-extension"

import rootReducer from "./rootReducer"
import rootSaga from "./rootSaga"
import { AuthAction } from "./auth/types"

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
)

sagaMiddleware.run(rootSaga)

export type { AppState } from "./rootReducer"
export type AppDispatch = typeof store.dispatch
export type AppAction = AuthAction

export default store

export * as storeAuthAction from "./auth/action"
export * as storeUserAction from "./user/action"
export * as storeShopAction from "./shop/action"
export * as storeAppVersionAction from "./appVersion/action"
export * as storeBookingRemarkAction from "./bookingRemark/action"
export * as storeSettingAction from "./setting/action"
export * as storeReservationHistoryAction from "./reservationHistory/action"
export * as storeCustomerAction from "./customer/action"
export * as storeUserLogAction from "./userLog/action"
export * as storeBookingAppAuthAction from "./bookingAppAuth/action"
export * as storeBookingAppReservationAction from "./bookingAppReservation/action"

export * as storeBookingAppReservationSelectors from "./bookingAppReservation/selectors"
