import axios from "axios"

import { commonConfig } from "@/utils/config"
import { jwtService } from "@/services"

import type { AxiosError } from "axios"

const bookingAppAxios = axios.create({
  baseURL: `${commonConfig.BOOKING_APP_API_HOST}api/`,
})

bookingAppAxios.interceptors.request.use(
  req => {
    if (!req?.headers?.Authorization) {
      const token = jwtService.getBookingAppToken()
      if (!!token) {
        req!.headers!.Authorization = `Bearer ${token}`
      }
    }

    switch ((req.method as string).toUpperCase()) {
      case "GET": {
        req.params = req.params || {}
        if (typeof req?.params?.["language"] === "undefined") {
          req!.params!["language"] =
            typeof window !== "undefined"
              ? window.NextPublic.lang.toLowerCase()
              : undefined
        }
        // Object.assign(req.params, {});
        break
      }
      case "POST": {
        // if (!(req.data instanceof FormData) && !!req.data) {
        //   req.data = commonHelpers.formatFormData(req.data);
        // }

        // if (req.data instanceof FormData) {
        // } else {
        //   req.data = req.data || {};
        //   // Object.assign(req.params, {});
        // }
        if (typeof req?.data?.["language"] === "undefined") {
          req!.data!["language"] =
            typeof window !== "undefined" ? "en_US" : undefined
        }
        break
      }
      case "PUT": {
        // if (!(req.data instanceof FormData) && !!req.data) {
        //   req.data = commonHelpers.formatFormData(req.data);
        // }
        // if (req.data instanceof FormData) {
        //   // req.data.append("language", window.NextPublic.lang);
        // } else {
        //   req.data = req.data || {};
        //   // Object.assign(req.params, {});
        // }
        break
      }
    }
    return req
  },
  err => {
    console.log(err)
    return Promise.reject(err)
  },
)

bookingAppAxios.interceptors.response.use(
  res => {
    return res
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      if (typeof window !== "undefined") {
      }
    }
    return Promise.reject(error)
  },
)

export default bookingAppAxios
