import { all, call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { axiosHelpers } from "@/utils/helpers"
import { bookingAppReservationApi } from "@/utils/apis"

import { fetchRequested, fetchSucceeded, fetchFailed } from "./action"
import { BookingAppReservationActionTypes } from "./types"

import type { FetchBookingAppReservationsSagaAction } from "./types"

function* fetchBookingAppReservations(
  action: FetchBookingAppReservationsSagaAction,
) {
  const { params, cancelToken } = action.payload || {}
  const { disableLoading } = action.meta || {}
  if (!disableLoading)
    yield put(
      fetchRequested({
        scope: "bookingAppReservations",
      }),
    )

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof bookingAppReservationApi.fetchBookingAppReservations>
    > = yield call(bookingAppReservationApi.fetchBookingAppReservations, {
      params,
      cancelToken,
    })

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "bookingAppReservations",
          data: response.data.list,
          count: response.data.count,
        }),
      )
    } else {
      yield put(
        fetchFailed({
          scope: "bookingAppReservations",
          error: response.message,
        }),
      )
    }
  } catch (e) {
    if (axios.isCancel(e)) return
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    yield put(
      fetchFailed({
        scope: "bookingAppReservations",
        error: message,
      }),
    )
  }
}

function* bookingAppReservationSaga() {
  yield all([
    takeEvery(
      BookingAppReservationActionTypes.FETCH_BOOKING_APP_RESERVATIONS_SAGA,
      fetchBookingAppReservations,
    ),
  ])
}

export default bookingAppReservationSaga
