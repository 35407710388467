export const DOCUMENT_TITLE = "Booking System"
export const APP_VERSION = "v1.0.33"
export const APP_NAME = "app"
export const API_HOST = process.env.NEXT_PUBLIC_API_HOST
export const BOOKING_APP_API_HOST = process.env.NEXT_PUBLIC_BOOKING_APP_API_HOST
export const BOOKING_APP_WEBSOCKET_ENDPOINT =
  process.env.NEXT_PUBLIC_BOOKING_APP_WEBSOCKET_ENDPOINT

export const FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_API_KEY
export const FIREBASE_AUTH_DOMAIN = process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN
export const FIREBASE_PROJECT_ID = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID
export const FIREBASE_STORAGE_BUCKET =
  process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID
export const FIREBASE_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_APP_ID
export const FIREBASE_MEASUREMENT_ID =
  process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
export const FIREBASE_MESSAGING_VAPID_KEY =
  process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_VAPID_KEY
