import { bookingAppAxios, AxiosResponseData } from "@/utils/axios"

import type {
  FetchBookingAppReservationsPayload,
  CheckBoookingAppReservationMemberPayload,
  CheckBoookingAppReservationMemberResponseData,
  CheckInBoookingAppReservationPayload,
  CancelBoookingAppReservationPayload,
} from "./bookingAppReservation.api.types"

const bookingAppReservationApi = {
  fetchBookingAppReservations: (
    payload?: FetchBookingAppReservationsPayload,
  ) => {
    return bookingAppAxios.get<AxiosResponseData>("reservations/search", {
      params: payload?.params,
    })
  },
  checkBoookingAppReservationMember: (
    payload: CheckBoookingAppReservationMemberPayload,
  ) => {
    return bookingAppAxios.post<
      AxiosResponseData<CheckBoookingAppReservationMemberResponseData>
    >("reservations/check-member", payload.params, {
      cancelToken: payload?.cancelToken,
    })
  },
  checkInBoookingAppReservation: (
    payload: CheckInBoookingAppReservationPayload,
  ) => {
    const { reservationId, ...params } = payload.params
    return bookingAppAxios.put<
      AxiosResponseData<CheckBoookingAppReservationMemberResponseData>
    >(`reservations/${reservationId}/active`, params, {
      cancelToken: payload?.cancelToken,
    })
  },
  cancelBoookingAppReservation: (
    payload: CancelBoookingAppReservationPayload,
  ) => {
    const { reservationId, ...params } = payload.params
    return bookingAppAxios.put<
      AxiosResponseData<CheckBoookingAppReservationMemberResponseData>
    >(`reservations/${reservationId}/cancel`, params, {
      cancelToken: payload?.cancelToken,
    })
  },
}

export default bookingAppReservationApi
