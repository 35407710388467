// Action Types
import type {
  FetchBookingAppReservationsPayload,
  FetchBookingAppReservationsResponseData,
} from "@/utils/apis/bookingAppReservation/bookingAppReservation.api.types"

export enum BookingAppReservationActionTypes {
  FETCH_REQUESTED = "@@bookingAppReservation/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@bookingAppReservation/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@bookingAppReservation/FETCH_FAILED",
  SET_BOOKING_APP_RESERVATIONS_FIELD_VALUES = "@@bookingAppReservation/SET_BOOKING_APP_RESERVATIONS_FIELD_VALUES",

  // Saga
  FETCH_BOOKING_APP_RESERVATIONS_SAGA = "@@bookingAppReservation/FETCH_BOOKING_APP_RESERVATIONS_SAGA",
}

// State

export interface BookingAppReservationState {
  bookingAppReservations: FetchBookingAppReservationsResponseData
  bookingAppReservationsCount: number
  bookingAppReservationsLoading: boolean
  bookingAppReservationsError: string
}

// ---- Reducer ----

export type FetchRequestedAction = {
  type: BookingAppReservationActionTypes.FETCH_REQUESTED
  payload: {
    scope: "bookingAppReservations"
  }
}

export type FetchSucceededAction = {
  type: BookingAppReservationActionTypes.FETCH_SUCCEEDED
  payload: {
    scope: "bookingAppReservations"
    data: BookingAppReservationState[FetchSucceededAction["payload"]["scope"]]
    count?: number
  }
}

export type FetchFailedAction = {
  type: BookingAppReservationActionTypes.FETCH_FAILED
  payload: {
    scope: "bookingAppReservations"
    error: string
  }
}

// ---- Saga ----

export type FetchBookingAppReservationsSagaAction = {
  type: BookingAppReservationActionTypes.FETCH_BOOKING_APP_RESERVATIONS_SAGA
  payload?: FetchBookingAppReservationsPayload
  meta?: {
    disableLoading?: boolean
  }
}

export type BookingAppReservationAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchBookingAppReservationsSagaAction
