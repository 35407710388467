import type { AppState } from "@/store"
import { createSelector } from "@reduxjs/toolkit"

export type CustomBookingApppReservation = ReturnType<
  typeof mapBookingApppReservation
>

const mapBookingApppReservation = (
  bookingApppReservation: AppState["bookingAppReservation"]["bookingAppReservations"][number],
) => {
  const depositStatus =
    bookingApppReservation?.deposits?.[0]?.status ?? "pending"

  return {
    ...bookingApppReservation,
    _isMale: bookingApppReservation?.booking?.guest?.title === "MALE",
    _actions: {
      canclled: true,
    },
    _bookingPersonCount:
      (bookingApppReservation?.booking?.numOfAdultSeat ?? 0) +
      (bookingApppReservation?.booking?.numOfBabySeat ?? 0) +
      (bookingApppReservation?.booking?.numOfChildSeat ?? 0),
    _tableCode: bookingApppReservation.reservationTables?.join(", "),
    _deposit: {
      total: (bookingApppReservation.deposits ?? []).reduce(
        (total, deposit) => {
          return Number(deposit?.value ?? 0) + total
        },
        0,
      ),
      status: depositStatus,
      color: ["confirmed", "paid"].includes(depositStatus)
        ? "#1ECB59"
        : "#FD4A4E",
    },
    _remarks: bookingApppReservation.remark
      .split(",")
      .map(remark => remark.trim()),
  }
}

export const selectBookingAppReservations = (state: AppState) =>
  state.bookingAppReservation.bookingAppReservations

export const selectBookingAppReservationGroupedByTime = createSelector(
  [selectBookingAppReservations],
  bookingAppReservations => {
    return bookingAppReservations.reduce(
      (bookingAppReservationsGroupedByTimes, bookingAppReservation) => {
        const bookingAppReservationsGroupedByTimeIndex =
          bookingAppReservationsGroupedByTimes.findIndex(
            bookingAppReservationsGroupedByTime => {
              return (
                bookingAppReservationsGroupedByTime.time.split(":")[0] ===
                bookingAppReservation.time.split(":")[0]
              )
            },
          )
        const newBookingAppReservation = mapBookingApppReservation(
          bookingAppReservation,
        )
        if (bookingAppReservationsGroupedByTimeIndex > -1) {
          bookingAppReservationsGroupedByTimes[
            bookingAppReservationsGroupedByTimeIndex
          ].bookingPersonCount += newBookingAppReservation._bookingPersonCount
          bookingAppReservationsGroupedByTimes[
            bookingAppReservationsGroupedByTimeIndex
          ].bookingAppReservations.push(newBookingAppReservation)
          bookingAppReservationsGroupedByTimes[
            bookingAppReservationsGroupedByTimeIndex
          ].bookingAppReservations.sort((a, b) => (a.time >= b.time ? 1 : -1))
        } else {
          bookingAppReservationsGroupedByTimes.push({
            time: `${bookingAppReservation.time.split(":")[0]}:00`,
            bookingPersonCount: newBookingAppReservation._bookingPersonCount,
            bookingAppReservations: [newBookingAppReservation],
          })
        }
        return bookingAppReservationsGroupedByTimes
      },
      [] as {
        time: string
        bookingPersonCount: number
        bookingAppReservations: ReturnType<typeof mapBookingApppReservation>[]
      }[],
    )
  },
)
