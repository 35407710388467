import { BookingAppReservationActionTypes } from "./types"
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchBookingAppReservationsSagaAction,
} from "./types"

// ---- REDUCER ----
export const fetchRequested = (
  payload: FetchRequestedAction["payload"],
): FetchRequestedAction => ({
  type: BookingAppReservationActionTypes.FETCH_REQUESTED,
  payload,
})

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"],
): FetchSucceededAction => ({
  type: BookingAppReservationActionTypes.FETCH_SUCCEEDED,
  payload,
})

export const fetchFailed = (
  payload: FetchFailedAction["payload"],
): FetchFailedAction => ({
  type: BookingAppReservationActionTypes.FETCH_FAILED,
  payload,
})

// ---- SAGA ----

export const fetchBookingAppReservationsSaga = (
  payload?: FetchBookingAppReservationsSagaAction["payload"],
  meta?: FetchBookingAppReservationsSagaAction["meta"],
): FetchBookingAppReservationsSagaAction => ({
  type: BookingAppReservationActionTypes.FETCH_BOOKING_APP_RESERVATIONS_SAGA,
  payload,
  meta,
})
