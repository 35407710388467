import { useEffect } from "react"
import useEventCallback from "./useEventCallback"

import { useBookingAppSocketIOContext } from "@/contexts/BookingAppSocketIO"

type OnUpdateTableNotificationData = {
  id: number
  status: string
  code: string
  tableStatus: string
  name: string
}[]

type OnUpdateSettingNotificationData = {
  type: string
  setting: {
    key: string
    value: string
  }
}

type OnInitData = {
  data: {
    settings: {
      key: string
      value: string
    }[]
    tables: {
      id: number
      status: string
      code: string
      tableStatus: string
      name: string
    }[]
  }
  status: boolean
}

type BookingAppSocketIOProps = {
  onConnect?: () => void
  onUpdateSettingNotification?: (data: OnUpdateSettingNotificationData) => void
  onUpdateTableNotification?: (data: OnUpdateTableNotificationData) => void
  onInit?: (data: OnInitData) => void
  onBeginActiveReservationNotify?: (data?: { id: number }) => void
  onActiveReservationNotify?: (data?: { id: number }) => void
  onCancelActiveReservationNotify?: (data?: { id: number }) => void
  onCheckInError?: (data?: { id: number }) => void
  onCreateReservationNotify?: (data?: any) => void
  onUpdateReservationNotify?: (data?: any) => void
  onDeleteReservationNotify?: (data?: any) => void
}

const useBookingAppSocketIO = (props?: BookingAppSocketIOProps) => {
  const {
    onConnect = () => {},
    onUpdateSettingNotification = () => {},
    onUpdateTableNotification = () => {},
    onInit = () => {},
    onBeginActiveReservationNotify = () => {},
    onActiveReservationNotify = () => {},
    onCancelActiveReservationNotify = () => {},
    onCheckInError = () => {},
    onCreateReservationNotify = () => {},
    onUpdateReservationNotify = () => {},
    onDeleteReservationNotify = () => {},
  } = props || {}

  const { socket } = useBookingAppSocketIOContext()

  const executeConnect = useEventCallback(onConnect)

  const executeUpdateSettingNotification = useEventCallback(
    onUpdateSettingNotification,
  )

  const executeInit = useEventCallback(onInit)

  const executeUpdateTableNotification = useEventCallback(
    onUpdateTableNotification,
  )

  const executeBeginActiveReservationNotify = useEventCallback(
    onBeginActiveReservationNotify,
  )

  const executeActiveReservationNotify = useEventCallback(
    onActiveReservationNotify,
  )

  const executeCancelActiveReservationNotify = useEventCallback(
    onCancelActiveReservationNotify,
  )

  const executeCheckInError = useEventCallback(onCheckInError)

  const executeCreateReservationNotify = useEventCallback(
    onCreateReservationNotify,
  )

  const executeUpdateReservationNotify = useEventCallback(
    onUpdateReservationNotify,
  )

  const executeDeleteReservationNotify = useEventCallback(
    onDeleteReservationNotify,
  )

  useEffect(() => {
    socket && socket.on("connection", executeConnect)
    return () => {
      socket && socket.off("connection", executeConnect)
    }
  }, [])

  useEffect(() => {
    socket && socket.on("updateSettingNotify", executeUpdateSettingNotification)
    return () => {
      socket &&
        socket.off("updateSettingNotify", executeUpdateSettingNotification)
    }
  }, [])

  useEffect(() => {
    socket && socket.on("init", executeInit)
    return () => {
      socket && socket.off("init", executeInit)
    }
  }, [])

  useEffect(() => {
    socket && socket.on("updateTableNotify", executeUpdateTableNotification)
    return () => {
      socket && socket.off("updateTableNotify", executeUpdateTableNotification)
    }
  }, [])

  useEffect(() => {
    socket &&
      socket.on(
        "beginActiveReservationNotify",
        executeBeginActiveReservationNotify,
      )
    return () => {
      socket &&
        socket.off(
          "beginActiveReservationNotify",
          executeBeginActiveReservationNotify,
        )
    }
  }, [])

  useEffect(() => {
    socket &&
      socket.on("activeReservationNotify", executeActiveReservationNotify)
    return () => {
      socket &&
        socket.off("activeReservationNotify", executeActiveReservationNotify)
    }
  }, [])

  useEffect(() => {
    socket && socket.on("checkInError", executeCheckInError)
    return () => {
      socket && socket.off("checkInError", executeCheckInError)
    }
  }, [])

  useEffect(() => {
    socket &&
      socket.on("createReservationNotify", executeCreateReservationNotify)
    return () => {
      socket &&
        socket.off("createReservationNotify", executeCreateReservationNotify)
    }
  }, [])

  useEffect(() => {
    socket &&
      socket.on("updateReservationNotify", executeUpdateReservationNotify)
    return () => {
      socket &&
        socket.off("updateReservationNotify", executeUpdateReservationNotify)
    }
  }, [])

  useEffect(() => {
    socket &&
      socket.on("deleteReservationNotify", executeDeleteReservationNotify)
    return () => {
      socket &&
        socket.off("deleteReservationNotify", executeDeleteReservationNotify)
    }
  }, [])

  useEffect(() => {
    socket &&
      socket.on(
        "cancelActiveReservationNotify",
        executeCancelActiveReservationNotify,
      )
    return () => {
      socket &&
        socket.off(
          "cancelActiveReservationNotify",
          executeCancelActiveReservationNotify,
        )
    }
  }, [])

  return {
    socket,
    methods: {},
  }
}

export default useBookingAppSocketIO
