import { combineReducers } from "redux"

import authReducer from "./auth/reducer"
import userReducer from "./user/reducer"
import shopReducer from "./shop/reducer"
import appVersionReducer from "./appVersion/reducer"
import bookingRemarkReducer from "./bookingRemark/reducer"
import settingReducer from "./setting/reducer"
import reservationHistoryReducer from "./reservationHistory/reducer"
import customerReducer from "./customer/reducer"
import userLogReducer from "./userLog/reducer"
import bookingAppAuthReducer from "./bookingAppAuth/reducer"
import bookingAppReservationReducer from "./bookingAppReservation/reducer"

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  shop: shopReducer,
  appVersion: appVersionReducer,
  bookingRemark: bookingRemarkReducer,
  setting: settingReducer,
  reservationHistory: reservationHistoryReducer,
  customer: customerReducer,
  userLog: userLogReducer,
  bookingAppAuth: bookingAppAuthReducer,
  bookingAppReservation: bookingAppReservationReducer,
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
