import { useEffect, useState } from "react"

import moment from "moment"

const useIntervalNow = () => {
  const [now, setNow] = useState(moment())

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(moment())
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return now
}

export default useIntervalNow
