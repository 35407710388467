import { createContext } from "react"

import type { Socket } from "socket.io-client"

export type BookingAppSocketIOContextProps = {
  socket: Socket
}

const BookingAppSocketIOContext = createContext<BookingAppSocketIOContextProps>(
  undefined!,
)

export default BookingAppSocketIOContext
