import { all, call, put, takeLatest } from "redux-saga/effects"
import axios from "axios"

import { axiosHelpers } from "@/utils/helpers"
import { bookingAppAuthApi } from "@/utils/apis"

import {
  BookingAppAuthActionTypes,
  CheckBookingAppAuthSagaAction,
  SignInBookingAppSagaAction,
} from "./types"
import {
  checkBookingAppAuthFailed,
  checkBookingAppAuthSucceeded,
  signInBookingAppSucceeded,
} from "./action"
import { jwtService } from "@/services"

function* signInBookingApp(action: SignInBookingAppSagaAction) {
  const { params } = action.payload
  const { resolve } = action.meta || {}

  try {
    const { data: response } = yield call(bookingAppAuthApi.signInBookingApp, {
      params,
    })
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        signInBookingAppSucceeded({
          ...response.data,
          shopCode: params.shopCode,
          mcId: params.mcId,
          id: response.data.userId,
          token: response.data.accessToken,
        }),
      )
    }
    resolve && resolve(response)
  } catch (e) {
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    resolve && resolve({ message })
  }
}

function* checkBoookingAppAuth(action: CheckBookingAppAuthSagaAction) {
  try {
    const token = jwtService.getBookingAppToken()
    const { data: response } = yield call(
      bookingAppAuthApi.checkBookingAppAuth,
      {
        params: {
          token,
        },
      },
    )
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        checkBookingAppAuthSucceeded({
          ...response.data,
        }),
      )
    } else {
      yield put(
        checkBookingAppAuthFailed({
          message: response.message,
        }),
      )
    }
  } catch (e: any) {
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    yield put(
      checkBookingAppAuthFailed({
        message,
        isNetworkError: e.code === "ERR_NETWORK",
      }),
    )
  }
}

function* bookingAppAuthSaga() {
  yield all([
    takeLatest(
      BookingAppAuthActionTypes.SIGN_IN_BOOKING_APP_SAGA,
      signInBookingApp,
    ),
    takeLatest(
      BookingAppAuthActionTypes.CHECK_BOOKING_APP_AUTH_SAGA,
      checkBoookingAppAuth,
    ),
  ])
}

export default bookingAppAuthSaga
