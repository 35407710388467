import { all, fork } from "redux-saga/effects"

import authSaga from "./auth/sagas"
import branchSaga from "./shop/sagas"
import userSaga from "./user/sagas"
import appVersionSaga from "./appVersion/sagas"
import bookingRemarkSaga from "./bookingRemark/sagas"
import settingSaga from "./setting/sagas"
import reservationHistorySaga from "./reservationHistory/sagas"
import customerSaga from "./customer/sagas"
import userLogSaga from "./userLog/sagas"
import bookingAppAuthReducerSaga from "./bookingAppAuth/sagas"
import bookingAppReservationSaga from "./bookingAppReservation/sagas"

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(userSaga),
    fork(branchSaga),
    fork(appVersionSaga),
    fork(bookingRemarkSaga),
    fork(settingSaga),
    fork(reservationHistorySaga),
    fork(customerSaga),
    fork(userLogSaga),
    fork(bookingAppAuthReducerSaga),
    fork(bookingAppReservationSaga),
  ])
}
